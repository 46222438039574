import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://b844281fca9faa60d5e011c1ab45f2e5@o4506380714246144.ingest.us.sentry.io/4507304710832128",

  integrations: [Sentry.replayIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /sentry-study\.pages\.dev/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
